.textset6__container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem 0;
    padding-top: 2rem;
    width: 80%;
}

.textset6__title{
    display: flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items: center;
}




.textset6__heading{
    margin: 1rem 0;
}
.textset6__subheading p{
    width: 80%;
}

.textset6__cards{
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(3,1fr);


}
.textset6__card{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.textset6__card__heading{
    margin-bottom: 1rem;
}


.textset6__img{

    width: 300px;
    padding: 2rem;
    border: 1px solid var(--themeGray);
    margin-right: 1rem;

}
.textset6__img img{
    width: 200px;
}



@media (min-width: 1600px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }




@media (min-width: 1920px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }



@media (min-width: 2241px) {
    /* Your CSS Styles for all devices larger than 2240px */
  }




  
  /* Minimum Width for All Devices */




/* ///////for larger////////////////////////////////////////////////////////////////////////////////////////////////////// */





@media (max-width: 1439px) {
    /* Your CSS Styles for Large (LG) Device */
    .textset6__subheading p {
        width: 70%;
    }  

}
  
  /* Maximum Width for Medium (MD) Laptops */
  @media (max-width: 1199px) {
    /* Your CSS Styles for Medium (MD) Laptop */
    .textset6__card{
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
    .textset6__cards{
        grid-template-columns: repeat(2,1fr);
    }
    .textset6__subheading p {
        width: 90%;
    }  
  }
  
  /* Maximum Width for Small (SM) Laptops */
  @media (max-width: 1023px) {
    /* Your CSS Styles for Small (SM) Laptop */
    
  }
  @media(max-width:900px){
    .textset6__title{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .textset6__container{
        width: 85%;
        margin: 1rem 0;
        margin-top: 0;
    }
    .textset6__cards{
        margin-bottom: 0;
    }
  }
  
  /* Maximum Width for Medium (MD) Tablets */
  @media(max-width: 767px) {
    /* Your CSS Styles for Medium (MD) Tablet */
    .textset6__cards{
        grid-template-columns: repeat(1,1fr);
    }
   .textset6__subheading p{
    width: 94%;
   }
  }
  @media(max-width:600px){
 .textset6__container{
    width: 90%;
 }
  }
  /* Maximum Width for Small (SM) Mobiles */
  @media (max-width: 575px) {
    /* Your CSS Styles for Small (SM) Mobile */
  }
  
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 340px) {
    /* Your CSS Styles for Extra Small (XS) Mobile */
  }