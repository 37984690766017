.projectgallery__container{
 width: 100%;
 display: flex;
 align-items: center;
 flex-direction: column;
 justify-content: center;
padding: 2rem 0;

}

.projectgallery__header{
    font-family: var(--fontprimary);
    line-height: 21px;
    width: 80%;
   ;
    display: flex;
}
.projectgallery_sub{
    font-family: var(--fontprimary);
    font-size: 14px;
    width: 80%;
    font-weight: 300;
    color: rgb(255, 255, 255);
    background-color: #000;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
}
.projectgallery__heading{
    font-family: var(--fontprimary);
    
}
.projectgallery__scroll{
  width: 100%;

}
.projectgallery__scroll >*{
    margin: 0px 20px;
}


.projectgallery__list{
    background-color: brown;
    padding: 10px;
    display: inline;
    margin: 20px;
    width: 400px;
    height: 400px;
}
.buttoncarosoul{
  background-color: aqua;
  width: 40px;
  height: 40px;
  z-index: 100;
  position: absolute;
  border: none;
  right: .3%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.imageslidercard__container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: 'center';
 /*    margin: 20px 20px; */
   padding: 20px;
}
.imageslidercard__container img{
    width: 100%;
}

.myitemclass{
    padding: 20px;
}

@media (min-width: 1600px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }




@media (min-width: 1920px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }



@media (min-width: 2241px) {
    /* Your CSS Styles for all devices larger than 2240px */
  }


@media (max-width: 1439px) {
    /* Your CSS Styles for Large (LG) Device */
  }
  
  /* Maximum Width for Medium (MD) Laptops */
  @media (max-width: 1199px) {
    /* Your CSS Styles for Medium (MD) Laptop */
  }
  
  /* Maximum Width for Small (SM) Laptops */
  @media (max-width: 1023px) {
    /* Your CSS Styles for Small (SM) Laptop */
    
  }
  @media (max-width: 900px) {
    /* Your CSS Styles for Small (SM) Laptop */
 .projectgallery__header{
    width: 85%;
 }
 .projectgallery__container{
    padding: 1rem 0;
 }
  }
  /* Maximum Width for Medium (MD) Tablets */
  @media (max-width: 767px) {
    /* Your CSS Styles for Medium (MD) Tablet */
  }
  @media (max-width: 600px) {
    /* Your CSS Styles for Medium (MD) Tablet */
    .projectgallery__header{
        width: 90%;
    }
    .projectgallery__scroll>*{
        margin: 0;
    }
    .imageslidercard__container{
        padding-top: .6;
        padding-bottom: 0;
    }
    .buttoncarosoul{
      scale: .8;
      top: 42%;
    }
  }
  
  /* Maximum Width for Small (SM) Mobiles */
  @media (max-width: 575px) {
    /* Your CSS Styles for Small (SM) Mobile */
  }
  
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 340px) {
    /* Your CSS Styles for Extra Small (XS) Mobile */
  }