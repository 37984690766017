.review__main__container{
    width: 80%;
    font-weight: 300;
    margin-top: 20px;
}
.reviews__container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
   margin: 1rem 0;
   border: 1px solid #999;
   border-top-left-radius: 11px;
   border-bottom-right-radius: 11px;
   
}

.reviews__img{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  /*     background-color: var(--pastelOrange); */
  /* border:1px solid #999; */
    margin-right: 20px;
    height: 319px;
    border-bottom-left-radius: 11px;
border-top-left-radius: 11px;
}
.reviews__img img{
    width: 100%;
    margin-left: 8px;
}
.reviews__content p:first-child{
    font-family: var(--fontprimary);
   
 
   
}
.reviews__content p:nth-child(2){
    font-family: var(--fontprimary);
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 10px;
    color: rgb(46, 46, 46);
   
}
.reviews__content{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.reviews__item{
    width: 100%;
  /*   background-color: rgb(241, 241, 241); */
  /* border:  1px solid #999; */
 
    border-bottom-right-radius: 11px;
    height: 245px;
    padding: 20px 30px;
}
.review__userpic{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.review__userpic p{
 font-size: 100px;


}

.review__text{
width: 90%;
}
.review__text p:first-child{
    font-family: var(--fontsecondary);
/*     font-size: 19px;
    font-weight: 600; */
    margin-top: 10px;
}
.review__text p:last-child{
    /* line-height: 14px; */
    font-weight: 400;
    font-size: 13px;
    font-family: var(--fontprimary);
    margin-top: 14px;
}


.review__scroll{
    width: 100%;
  
  }
  .review__scroll >*{
      margin: 0px 20px;
  }

@media(max-width:1025px){
    .reviews__item{
        padding: 1rem;
    }
    .review__userpic {
   margin-top: .5rem;
    }
    .reviews__img img {
        width: 100%;
        
    }
}

@media(max-width:900px){
    .review__main__container{
        width: 85%;
    }
    .reviews__container{
       
        align-items: flex-start;
    }
    .reviews__container{
        flex-direction: column;
    }
    .reviews__content{
        width: 100%;
    }
    .reviews__img{
     display: none;
    }
    
}


@media(max-width:601px){
    .review__main__container{
        width: 90%;
    }
    .review__userpic{
        margin-top: 0;
    }
    .reviews__item{
        height: fit-content;
    }
}