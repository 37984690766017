.footer__container{
   
    width: 100%;
    padding-top: 2%;
    /* height: 350px; */
    padding-bottom: 25px;
    background-color: #343333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;

 /*    box-shadow: rgba(0, 0, 0, 0.35) 0px 6px 15px; */
/*  background-color: var(--pastelCyan); */
}
.footer__img__logo{
  /* padding-left: 10%; */
  height: 45px;
  margin-bottom: 8px;
}
.footer__container__Detail{
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* padding-left: 10%;
  padding-right: 5%; */
}

.footer__links{
    /* display: grid;
    grid-template-columns: repeat(3, 2fr);
    justify-content: space-between;
    width: 60%;
    height: 100%;
    line-height: 2; */
    /* display: grid;
    grid-template-columns: repeat(3, 2fr); */
    /* justify-content: center; */
    /* width: 60%; */
    height: 100%;
    line-height: 2;
}
.footer__link_container{
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  align-items: flex-start;
  justify-content: center;
}
.footer__linkbox{
    margin: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 99%;
  margin: 13px 1px;
  padding-right: 40px;
}
.footer__heading{
    /* font-family: var(--fontsecondary); */
    font-weight: 500;
    font-size: 19px;
    color: #ffffff;
    /* margin: 8px 0px; */
}
.footer__linkbox p{
    font-family: var(--fontprimary);
    color: #e8e8e8;
    font-size: 14px;  
    cursor: pointer;
}


.footer__socials{
    width: 20%;
    height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-end;
    margin-top: 15px;
}

.copyright{
    font-family: var(--fontprimary);
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    margin-top: auto;
    grid-column: 1 / 2;
    /* height: 50px; */
    display: flex;
    align-items: flex-end;

}

.footer__socials p{
    font-family: var(--fontsecondary);
    font-weight: 500;
    font-size: 19px;
    color: #fff;
    margin: 8px 0px;
}

.footer__icons{
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.footer__icons a{
    /* margin-top: 10px;
    margin-left: 15px; */
    background: #121212;
    border-radius: 50%;
    display: flex;
    align-items: center;
    padding: 10px;
    color: #818080;
}
.footer__icons :nth-child(1){
  margin-left: 0;
}
.iconImg{
    width: 100px;
    position: absolute;

}

.footer__newsletter{
  width: 40%;
  margin: 13px 1px;
}

.footernewsletter__text{
  display: flex;
  /* font-family: var(--fontprimary); */
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  color: #fff;
}

.footernewsletter__text input{
  width: 400px;
  height: 39px;
  margin: 10px 0;
  border-radius: 5px;
  color: #000;
  width: 100%;
}
.footer__Newletter__container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

}
.footernewsletter__buttonset2{
  width: 110px;
  height: 40px;
  background-color: var(--themeOrange);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s linear;
}
.footernewsletter__buttonset2:hover{
  background-color: transparent;
  color:var(--themeOrange);
  border: 1px solid var(--themeOrange);
}











@media (max-width: 430px) {
  /* Your CSS Styles for Extra Large (XL) Device */
  .footer__container__Detail {
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column!important;
    }
}

@media (min-width: 1600px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }




@media (min-width: 1920px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }



@media (min-width: 2241px) {
    /* Your CSS Styles for all devices larger than 2240px */
  }




  
  /* Minimum Width for All Devices */




/*//////for larger/////////////////////////////////////////////////////////////////////////////////////////////////////*/





@media (max-width: 1439px) {
    /* Your CSS Styles for Large (LG) Device */
  }
  
  /* Maximum Width for Medium (MD) Laptops */
  @media (max-width: 1199px) {
    /* Your CSS Styles for Medium (MD) Laptop */
  }
  
  /* Maximum Width for Small (SM) Laptops */
  @media (max-width: 1023px) {
    /* Your CSS Styles for Small (SM) Laptop */
    /* .footer__container{
        height: 324px;
    } */
  }
  
  /* Maximum Width for Large (LG) Tablets */
  @media (max-width: 991px) {
    /* Your CSS Styles for Large (LG) Tablet */
    .footer__heading {
        font-size: 17px;
    }
    .footer__linkbox p{
        font-size: 12px;
    }
    .footer__socials p{
        font-size: 17px;
    }
    .footer__icons img {
        margin-top: 2px;
        margin-left: 10px;
        width: 25px;
    }
  }
  @media (max-width: 900px) {
    /* Your CSS Styles for Small (SM) Mobile */
    /* .footer__container{
      padding-left: 7.5%;
      padding-right: 7.5%;
    } */
    .footer__container__Detail{
      width: 85%;
    }
  }
  
  /* Maximum Width for Medium (MD) Tablets */
  @media (max-width: 767px) {
    /* Your CSS Styles for Medium (MD) Tablet */
    .footer__socials{
        align-self:flex-start;
    }
    .footer__links{
        /* display: grid; */
       
       /*  grid-template-rows: repeat(2, 2fr); */
        width: 100%;
     /*    background-color: blue; */
        height: 100%;
    }
    .footer__link_container{
      grid-template-columns: repeat(2, 2fr);
    }
    .copyright{
        font-size: 11px;
      align-items: center;
        
    }
    .footer__container{
   /*      padding-left: 10%;
        padding-right: 10%; */
        height: fit-content;
    }
  }
  @media (max-width: 700px) {
    /* Your CSS Styles for Small (SM) Mobile */
    /* .footer__container{
      padding-left: 5%;
      padding-right: 5%;
    } */
    .footer__newsletter{
      width: 60%;
    }
  }
  @media (max-width: 600px) {
    /* Your CSS Styles for Small (SM) Mobile */
    /* .footer__container{
      padding-left: 5%;
      padding-right: 5%;
    } */
    .footer__container__Detail{
      width: 90%;
    }
  }
  @media (max-width: 500px) {
    .footer__link_container .footer__linkbox:nth-child(2){
      margin-top: 50%;
    }
    .footer__newsletter{
      width: 100%;
    }
  }
  
  /* Maximum Width for Small (SM) Mobiles */
  @media (max-width: 575px) {
    /* Your CSS Styles for Small (SM) Mobile */
    /* .footer__container{
        flex-direction: column-reverse;
        height: fit-content;
        align-items: flex-start;
    } */
    .footer__socials{
        align-items: flex-start;
    }
    .footer__icons{
      margin-top: auto;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 1px;
    }
    .footer__icons img {
        margin-top: 2px;
        margin-left: 0px;
        margin-right: 10px;
        width: 25px;
    }

    .footer__socials{
        width: 100%;
    }
  }
  
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 300px) {
 
    .footer__linkbox{
      padding-right:0;
  }
  }
  @media (max-width: 250px) {
    .footer__link_container .footer__linkbox:nth-child(2){
      margin-top: auto;
      align-items: flex-start;
      padding-left: 12px;  
    }
   .footer__link_container {
    grid-template-columns: repeat(1,2fr);
}
  }
  