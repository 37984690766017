.textset2__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
 

}

.textset2__img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 67.77777777%;
   /*  background-color: var(--pastelYellow); */
   border-bottom-left-radius: 10px;
}
.textset2__imgtext{
   
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
}

.textset2__img img{
    width: 250px;
    padding: 2rem 0rem;
}

.textset2__text{
   
    display: grid;
    width: 104%;
    grid-template-columns: repeat(2, 1fr); /* Three columns with equal width */
      gap: 20px; /* Gap between grid items */
 /*      grid-template-rows: repeat(2, 1fr);  *//* Three columns with equal width */
      
}

.textset2__item{
    display: flex;
   
    align-items: flex-start;
    padding: 1;
    flex-direction: column;
    padding: 0.5rem;
}

.textset2__item p:first-child{
  

font-family: var(--fontprimary);
color: #000;
font-size: 18px;
font-style: normal;
font-weight: 500;
text-transform: uppercase;

}
.textset2__item p:last-child{
    color: #525252;

font-family: var(--fontprimary);
font-size: 15px;
font-style: normal;
width: 100%;
font-weight: 300;
line-height: 20px; /* 131.579% */
}

.textset2__minheading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* width: 30%; */
    margin-left:0 ;
}

.textset2__sheading{
    text-transform: uppercase;
    margin: 1rem 0;
}







@media (min-width: 1600px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }




@media (min-width: 1920px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }



@media (min-width: 2241px) {
    /* Your CSS Styles for all devices larger than 2240px */
  }




  
  /* Minimum Width for All Devices */




/* ///////for larger////////////////////////////////////////////////////////////////////////////////////////////////////// */





@media (max-width: 1439px) {
    /* Your CSS Styles for Large (LG) Device */
  }
  
  /* Maximum Width for Medium (MD) Laptops */
  @media (max-width: 1199px) {
    /* Your CSS Styles for Medium (MD) Laptop */
   
  }
  
  /* Maximum Width for Small (SM) Laptops */
  @media (max-width: 1023px) {
    /* Your CSS Styles for Small (SM) Laptop */
    .textset2__img{
        width: 50%;
    }
    .textset2__minheading{
        width:50%;
    }
  }

  
  
  /* Maximum Width for Medium (MD) Tablets */
  @media (max-width:900px) {
    /* Your CSS Styles for Medium (MD) Tablet */
    .textset2__container{
      width: 85%;
    }
  }
  @media(max-width:600px){
    .textset2__container{
      width: 90%;
    }
    .textset2__imgtext {
        flex-direction: column;
        align-items: flex-start;
    }
    .textset2__minheading{
        width: 91%;
    }
    .textset2__text{
        margin: 1rem 0;
        
    }
    .textset2__item{
        padding: 0;
    }
    .textset2__text {
      
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        /* grid-template-rows: repeat(2, 1fr); */
    }
    .textset2__item p:first-child {
       
        
        font-size: 15px;
     
    }
  }
  /* Maximum Width for Small (SM) Mobiles */
  @media (max-width: 575px) {
    /* Your CSS Styles for Small (SM) Mobile */
  }
  
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 340px) {
    /* Your CSS Styles for Extra Small (XS) Mobile */
  }

/* /////master width */
/* .master__width{
    width: 80%;
}
  @media (max-width: 1023px) {

    .master__width{
        width: 85%;
    }
  }
  @media (max-width: 767px) {
    
    .master__width{
        width: 88%;
    }
  }
  @media (max-width: 400px) {

    .master__width{
        width: 94%;
    }
  } */


