.textsetmini2__container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 1.1rem 0;
    width: 80%;
}
.textsetmini2__titles{
    margin-bottom: 1rem;
  /*   margin-left: 1rem; */
}
.textsetmini2__img{
    width: 250px;
   /*  border: 1px solid var(--themeGray); */
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.textsetmini2__img img{
 /*    padding: 2rem; */
    width: 210px;
}

.textsetmini2__heading{
    padding-top: 1rem;
    padding-bottom: .2rem;
}
/* .textsetmini2__subheading p{
    width: 70%;
} */


@media (min-width: 1600px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }




@media (min-width: 1920px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }



@media (min-width: 2241px) {
    /* Your CSS Styles for all devices larger than 2240px */
  }




  
  /* Minimum Width for All Devices */




/* ///////for larger////////////////////////////////////////////////////////////////////////////////////////////////////// */





@media (max-width: 1439px) {
    /* Your CSS Styles for Large (LG) Device */
  }
  
  /* Maximum Width for Medium (MD) Laptops */
  @media (max-width: 1199px) {
    /* Your CSS Styles for Medium (MD) Laptop */
    .textsetmini2__subheading p {
        width: 90%;
    }
  }
  
  /* Maximum Width for Small (SM) Laptops */
  @media (max-width: 900px) {
    /* Your CSS Styles for Small (SM) Laptop */
   .textsetmini2__container{
    width: 85%;
   }
    
  }
  
  /* Maximum Width for Medium (MD) Tablets */
  @media (max-width: 600px) {
    /* Your CSS Styles for Medium (MD) Tablet */
    .textsetmini2__container{
      width: 90%;
     }
  }
  
  /* Maximum Width for Small (SM) Mobiles */
  @media (max-width: 575px) {
    /* Your CSS Styles for Small (SM) Mobile */
  }
  
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 340px) {
    /* Your CSS Styles for Extra Small (XS) Mobile */
  }