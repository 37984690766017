.button__container{
 display: flex;
 justify-content: center;
 align-items: center;
}
.button__container button{
    width: 200px;
    height: 50px;
    background-color: #000000;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: var(--fontprimary);
    font-size: 15px;
    font-weight: 300;
    border: none;
    margin-top: 1rem;
    overflow: hidden;
}