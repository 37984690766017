.technology__container{
    width: 100%;
 display: flex;
 align-items: center;
 flex-direction: column;
 justify-content: center;
padding-top: 2rem;
}

.sidu{
    transition: all 5s linear;
}

.technology__header{
    font-family: var(--fontprimary);
    font-size: 22px;
    width: 80%;
    font-weight: 300;
}

.technology__heading{
/*     font-family: var(--fontprimary);
    font-size: 21px; */
    width: 80%;
    /* font-weight: 600; */
}
.technology__content{
    width: 80%;
    padding: 20px 0px;
}

.techcard__container{
    width: 180px;
    height: 160px;
    display: flex;
    border-radius: 4px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.techcard__container p{
    text-transform: uppercase;
    font-family: var(--fontprimary);
    font-weight: 300;
    color: #504e4e;
}


.techcard__container img{
    width: 40%;
}



@media(max-width:900px){
    .technology__content{
        padding: 0;
    }
    .techcard__container{
        width: 100px;
        height: 100px;
        
    }
    .technology__header{
        width: 85%;
    }
    .techcard__container p{
        text-transform: uppercase;
        font-family: var(--fontprimary);
        font-weight: 300;
        color: #504e4e;
        font-size: 13px;
    }
}


@media(max-width:600px){
    .techcard__container{
        width: 70px;
        height: 70px;
    }
    .technology__header{
        width: 90%;
    }

    .techcard__container p {
   
        font-size: 8px;
    }
}