.Listbox__container{
    width: 100%;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
}
.listbox__header{
    width: 80%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
}

.Listbox__mini__container h3{
    text-align: center;
    padding-top: 21px;
    padding-bottom: 10px;
}
/* .Listbox__mini__container p{
    line-height: 21px;
} */

.lsitbox__about__img img{
    width: 200px;
    height: 100%;
}
.listbox__sub__header{
    width: 80%;
    margin-bottom: 20px;
}
.listbox__sub__header ul{
    list-style: none;
}
.listbox__sub__header ul li{
    font-family: var(--fontprimary);
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
}
.listbox__secound__container{
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

/* Maximum Width for Small (SM) Mobiles */
@media (max-width: 900px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .listbox__header{
      width: 85%;
    }
  }
  
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 600px) {
    /* Your CSS Styles for Extra Small (XS) Mobile */
    .listbox__header{
        width: 90%;
        flex-direction:column;
      }
  }