body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.pagecontainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

::selection {
  background-color: #000000; /* Change this to the desired color */
  color: #fff; /* Change this to the desired text color */
}



:root {
  --pastelLeaf:#E3FACD;
  --pastelPink:#FADDCC;
  --pastelPurple:#E0CCFA;
  --pastelCyan:#CCFACE;
  --pastelBlue:#CDDFFA;
  --pastelYellow:#FAF8CD;
  --pastelMuddy:#E9F2B3;
  --pastelClay: #F2EFB3;
  --pastelOrange:#ffeabf;
  --themeOrange: #fd9f1d;
  --themeGray: rgb(193, 193, 193);
  --fontprimary:'Catamaran', sans-serif;
  --fontsecondary:'Signika Negative', sans-serif;
  --headingColor:#000;



  --headingFont:'Catamaran';
  --headingWeight:600;
  --headingSize:21px;
  
}


.heading1{
  color: var(--themeOrange);
  font-size: 21px;
  font-weight: 600;
  font-family: 'Catamaran';
  text-transform: uppercase;
}
.heading2{
  color: var(--themeOrange);

 
  font-family: var(--fontprimary);
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 140% */
  text-transform: uppercase;
}
.subheading{
  color: #000;
  font-family: var(--fontprimary);
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;     
}

.heading3{
  color: #000;
    text-align: left;
    font-family: var(--fontsecondary);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}
.subheading2{
  color: #555;
  font-family: var(--fontprimary);
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;     
}


.heading4{
  color: var(--themeOrange);
  font-family: var(--fontprimary);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.subheading4{
  color: #000000;
  font-family: var(--fontprimary);
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
}

.maininnercontainer{
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media(max-width:1090px){
  .heading1{
    font-size: 17px;
  }
  .heading2{
    font-size: 17px;
  }
 
}

@media(max-width:900px){
  .heading1{
    font-size: 16px;
  }
  .heading2{
    font-size: 16px;
  }
  /* .maininnercontainer{
    width: 85%;
  } */
}


@media(max-width:600px){
  .heading1{
    font-size: 16px;
  }
  .heading2{
    font-size: 16px;
  }
 /* .maininnercontainer{
  width: 90%;
 } */
}
















/* for 900 and above 80%
below 900 is 85%
and below 600 is 90% */