.textset__container{
 width: 80%;
 display: flex;
 justify-content: center;
 align-items: center;
/*  height: 300px; */
 margin: 2rem 0rem;
/*  border-top: 1px solid var(--themeGray);*/

 padding: 1rem 0;

}

.textset__img{
  width: 30%;
  background-color: var(--pastelMuddy);
  display: flex;
  justify-content: center;
/*   align-items: center; */
height: 100%;
}
.textset__img img{
    width: 80%;
    align-self: center;
}
.textset__text{
    width: 68%;
    margin-left: 2%;
 /*    margin-top: auto;
    margin-bottom: 2rem; */
}
.textset__text p:first-child{
   


text-transform: uppercase;

line-height: 25px; /* 119.048% */
margin-bottom: .5rem;
width: 55%;
}

.textset__text p:nth-child(2){
    color: #000000;

    font-family: var(--fontprimary);
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px; /* 140% */
    width: 74%;
    white-space: pre-line;
  
}

.textset__text button{
    width: 200px;
    height: 50px;
    background-color: #000;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: var(--fontprimary);
    font-size: 15px;
    font-weight: 300;
    border: none;
    margin-top: 1rem;
}




@media (min-width: 1600px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }




@media (min-width: 1920px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }



@media (min-width: 2241px) {
    /* Your CSS Styles for all devices larger than 2240px */
  }




  
  /* Minimum Width for All Devices */




/* ///////for larger////////////////////////////////////////////////////////////////////////////////////////////////////// */





@media (max-width: 1439px) {
    /* Your CSS Styles for Large (LG) Device */
    .textset__text p:nth-child(2) {
    width: 88%;
    }
  }
  
  /* Maximum Width for Medium (MD) Laptops */
  @media (max-width: 1199px) {
    /* Your CSS Styles for Medium (MD) Laptop */
    .textset__text p:nth-child(2) {
        width: 100%;
        }
  }
  
  /* Maximum Width for Small (SM) Laptops */
  @media (max-width: 1023px) {
    /* Your CSS Styles for Small (SM) Laptop */
    
  }
    /* Maximum Width for Medium (MD) Tablets */
    @media (max-width: 900px) {
        /* Your CSS Styles for Medium (MD) Tablet */
        .textset__container{
          width: 85%;
            flex-direction: column;
        }
        .textset__img {
            width: 59%;
            padding: 2rem;
            padding-left: 0;
            display: flex;
            justify-content: flex-start;
        }
        .textset__button{
            padding: 1rem 0;
        }
        .textset__text{
            width: 79%;
            margin-left: 0;
        }
      }
  
  /* Maximum Width for Medium (MD) Tablets */
  @media (max-width: 767px) {
    /* Your CSS Styles for Medium (MD) Tablet */
    .textset__text{
        width: 90%;
    }
    .textset__text p:first-child{
        width: 90%;
    }
    .textset__container{
        margin: 1rem 0;
    }
  }
  
  /* Maximum Width for Small (SM) Mobiles */
  @media (max-width: 600px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .textset__container{
      width: 90%;
    }
  }
  
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 340px) {
    /* Your CSS Styles for Extra Small (XS) Mobile */
  }