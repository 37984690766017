.statics__main__container{
    /* background-color: #00000008; */
    display: flex;
    justify-content: center;
    padding: 30px 0;
    width: 100%;
}

.statics__container{
    width:80%;
    max-width: 1440px;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* background-color: mediumblue; */
}
/* .square{
  position: absolute;
  width: 200px;
  height: 20px;
  background-color: var(--themeOrange);
  z-index: -2;
  margin-left: -20px;
} */

.statics__title{
    width: 60%;
    margin-bottom: 20px;
    position: relative;
}


/* .statics__heading{
 font-family: 'Catamaran';
 font-size: 21px;
 font-weight: 600;

} */

.statics__subheading{
   font-size: 14px;
   line-height: 21px;
   color: rgb(84, 84, 84);
}
.statics__list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
/* padding: 0px 20px; */
/* margin-right: 20px; */
width: 40%;
}
.statics__list div:first-child{
    border-top-left-radius: 11px;

}

.statics__list div:last-child{
    border-bottom-right-radius: 11px;
}

.statics__stat{
  display: flex;
  justify-content: space-between;   /* NOT IMPLE */
 /*  background-color: crimson; */
 flex-direction: row-reverse;
/*  background-color: cadetblue; */
 width: 100%;

 margin-top: 10px;

}
.statics__stat img{
    padding:  0px;
   height: 500px;
   border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;
    /* background-color: rgb(250, 211, 217); */
    padding-left: 29px;
    width: 100%;
 
}
.statics__listitem{
display: flex;
flex-direction: column;
width: 100%;
justify-content: center;
align-items: center;
height: 20%;
padding: 0px 20px;
background-color: #fff;
border: 1px solid var(--themeGray);
}
.statics__listitem p:first-child{
  font-family: var(--fontsecondary);
  font-size: 25px;
  font-weight: 400;

}
.statics__listitem p:last-child{
    font-family: var(--fontsecondary);
    font-size: 15px;
    font-weight: 300;
    color: var(--themeOrange);

  
  }
  
@media(min-width:1440px){

}
@media(max-width:1300px){

    /* .statics__stat img{
        width: 750px;
    } */
/*     .statics__heading{
        font-size: 25px;
        line-height: 31px;
        font-weight: 300;
    
    } */
   .statics__subheading{
    font-size: 12px;
   }
   
.statics__listitem p:first-child{
    font-size: 20px;
}

.statics__listitem p:last-child{
    font-size: 12px;
}





}

@media(max-width:1080px){
    /* .statics__stat img{
        width: 600px;
    } */
    .statics__heading{
        font-size: 21px;
        line-height: 26px;
    }
   .statics__subheading{
    font-size: 12px;
   }
   .statics__list{
  /*   padding: 0px 10px; */
   }
.statics__listitem p:first-child{
    font-size: 20px;
}

.statics__listitem p:last-child{
    font-size: 12px;
}


}
@media(max-width:1020px){
    .statics__stat{
        flex-direction: column-reverse;
    }
    .statics__listitem{

        padding: 0;
        width: 22%;
        height: 100px;
    }
.statics__list{
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    
}
.statics__stat img {
    
    margin-top: 2%;
}

.statics__list div:last-child {
    border-bottom-left-radius: 0px;
    /* background-color: var(--pastelCyan); */
    border-top-right-radius: 11px;
}
}
@media(max-width:900px){
    .statics__container{
        width: 85%;
    }
    
}

@media(max-width:810px){
    
    /* .statics__stat img{
        width: 500px;
    } */
    .statics__heading{
        font-size: 16px;
        line-height: 21px;
    
    }
   .statics__subheading{
    font-size: 11px;
   }
   .statics__list{
    /* padding: 0px 10px; */
   }
.statics__listitem p:first-child{
    font-size: 16px;
}

.statics__listitem p:last-child{
    font-size: 11px;
}
.statics__title{
    width: 70%;
}

}
@media(max-width:720px){
        
    .statics__stat img {
       
        height: 300px;
    }
    .statics__heading{
        font-size: 14px;
        line-height: 20px;
    
    }
   .statics__subheading{
    font-size: 10px;
   }
   .statics__list{
   
   }
.statics__listitem p:first-child{
    font-size: 14px;
}

.statics__listitem p:last-child{
    font-size: 9px;
}
.statics__title{
    width: 76%;
}

}
@media(max-width:640px){
    /* .statics__stat img{
        width: 350px;
    } */
    .statics__heading{
        font-size: 14px;
        line-height: 20px;
    
    }
   .statics__subheading{
    font-size: 10px;
   }
   .statics__list{
 
   }
.statics__listitem p:first-child{
    font-size: 14px;
}

.statics__listitem p:last-child{
    font-size: 9px;
}
.statics__title{
    width: 76%;
}
.statics__listitem{
    height:80px;
}
}
@media(max-width:600px){
    .statics__container{
        width: 90%;
    }
    .statics__title{
        width: 95%;
    }
}
@media(max-width:530px){
    
    .statics__heading{
        font-size: 14px;
        line-height: 20px;
    
    }
   .statics__subheading{
    font-size: 10px;
   }
   .statics__list{
   
   }
.statics__listitem p:first-child{
    font-size: 14px;
}

.statics__listitem p:last-child{
    font-size: 9px;
}

.statics__listitem{
    height: 60px;
}

.statics__listitem {
    padding: 0;
    width: 47.5%;
    height: 100px;
    margin-bottom: 1rem;
}
.statics__list{
    flex-wrap: wrap;
}
}
