.line__container{
    width: 80%;
    height: 1px;
   border-bottom: 1px solid #fd9f1c;
}

@media (max-width: 900px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .line__container{
      width: 90%;
      
    }
  }

  @media (max-width: 600px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .line__container{
      width: 90%;
      
    }
  }