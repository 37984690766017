.appdevpage__container,
.appdev__maincon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}



/* 
@media(max-width:900px){
    .appdev__maincon{
        width: 85%;
    }
}

@media(max-width:600px){
    .appdev__maincon{
        width: 90%;
    }
} */

