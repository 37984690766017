.header__container{
    display: flex;
   width: 100vw;
   justify-content: flex-start;
   align-items: center;
   flex-direction: column;

/*    border-bottom: 5px solid var(--themeOrange); */
/*    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11); */
   position: fixed;
   top: 0;
   background-color: rgba(255, 255, 255, 0.738);
 
   z-index: 100;
}
.header__inner{
    display: flex;
  width: 80%;
  max-width: 1440px;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  margin-top: 20px;
  

}

.header__logo{
 margin-left: -11px;
}
.header__link{
 height: 100%;

 display: flex;
 justify-content: center;
 align-items: center;
 
}

.header__link > *{
/*     margin: 0px 15px; */
    text-decoration: none;
    font-family: var(--fontprimary);
    font-weight: 300;
    color: #000;
    font-size: 13px;
    display: block;
    text-transform: uppercase;
    height: 64px;
    width: 90px;
  
    display: flex;
    justify-content: center;
    align-items: center;
}
.header__link >*:hover{
  color: rgb(239, 172, 26);

}

.header__logo img{
  
      height: 60px;
}
.header__inner svg{
  display: none;                              /*sub menuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu*/
}
            
.header__submenu{
  width: 100%;
  height: 50px;
  background-color: #000000;
  display: none;
  justify-content:center;
  align-items: center;
  
}
.submenu__links{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;
  /* background-color: beige; */

}
.submenu__links > *{
  font-family: var(--fontprimary);
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-weight: 300;
  
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  /* background-color: chartreuse; */
  padding: 0px 12px;

}
.header__container.transitioning-up {
  transition-duration: .7s;
  transform: translateY(-100px);
}

.header__container.transitioning-down {
  transition-duration: .7s;
  transform: translateY(0);
}
.header__sidebar{
  width: 80vw;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--themeOrange);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header__sidebar > *{
  font-family: var(--fontprimary);
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 300;
  
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  /* background-color: chartreuse; */
  padding: 12px 0px;
}

@media(min-width:1441px){

}
@media(max-width:1200px){
 
}

@media(max-width:1080px){
   .header__container{
  /*   height: 50px; */
   }
   .header__link > *{
    margin: 0px 10px;
   font-size: 14px;
    font-weight: 300;
    padding: 0px 0px;
    width: 70px;
    height: 50px;
 
}   .header__logo img {
  height: 40px;


}
.header__inner{
  height: 50px;
}
 .submenu__links >*{
  font-size: 10px;
 }
}

@media(max-width:900px){
  .header__inner{
    width: 85%;
  }
}




@media(max-width:810px){
  .header__inner{
    height: 45px;
  }
  .header__container{
  /*   height: 50px; */
   }
   .header__link > *{
    margin: 0px 5px;
   font-size: 11px;
    font-weight: 300;
    padding: 0px 0px;
    width: 60px;
    height: 50px;

 
}  
/* ///////////////////////////////Mobile starts from here//////////////////////////////////////////////////////// */
}

@media(max-width:750px){
  .submenu__links >*{
   padding-left: 0;
  }
}




@media(max-width:650px){
  .header__logo img {
    height: 30px;
  
  
  }
  
 .header__link{
   display: none;
 }
 .header__inner svg{
   display: block;
 }



}

@media(max-width:600px){
  .header__inner{
    width: 90%;
  }
}
















@media(max-width:530px){
   
}