.aboutpage__container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.about__sub__maininnercontainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
}

@media(max-width:600px){
    .about__sub__maininnercontainer{
        justify-content:center;
        flex-direction: column;
        width: auto;
    }
    
}