.work__technology__container{
    width: 80%;
 display: flex;
 align-items: center;
 flex-direction: column;
 justify-content: center;
padding-top: 2rem;
}

.work__technology__header{
    font-family: var(--fontprimary);
    font-size: 22px;
    font-weight: 300;
}

.work__technology__heading{
   font-family: var(--fontprimary);
    margin-top: 20px;
    font-size: 18px;
    line-height: 26px;
    text-wrap: pretty;
    white-space: pre-line;
    font-weight: 400;
}
.work__technology__content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}

.techcard__container{
    width: 180px;
    height: 160px;
    display: flex;
    border-radius: 4px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.techcard__container p{
    text-transform: uppercase;
    font-family: var(--fontprimary);
    font-weight: 300;
    color: #504e4e;
}


.techcard__container img{
    width: 40%;
}



@media(max-width:900px){
    .work__technology__content{
       
        padding: 0;
    }
    .work__technology__container{
        width: 85%;
    }
    .techcard__container{
        width: 100px;
        height: 100px;
        
    }
   
    .techcard__container p{
        text-transform: uppercase;
        font-family: var(--fontprimary);
        font-weight: 300;
        color: #504e4e;
        font-size: 13px;
    }
}


@media(max-width:600px){
    .techcard__container{
        width: 70px;
        height: 70px;
    }
    .work__technology__container{
        width: 90%;
        padding-top: 0;
    }
    .work__technology__heading{
        margin-top: 0;
    }

    .techcard__container p {
   
        font-size: 8px;
    }
}