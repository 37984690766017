.itconsulting__wholecontent,itconsulting__wholecontent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.container{
    display: flex;
    justify-content: center;    
    
}
.main{
    display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
}
.container1 {
    display: flex;
    max-width: 80%;
    align-items: center;

}
.line {
    width: 80%;
    height: 1px;
    border-bottom: 1px solid #fd9f1c;
}

@media (max-width: 900px) {

    /* Your CSS Styles for Small (SM) Mobile */
    .line__container {
        width: 90%;

    }
}

@media (max-width: 600px) {

    /* Your CSS Styles for Small (SM) Mobile */
    .line__container {
        width: 90%;

    }
} 
@media(max-width:900px){
 
}

@media(max-width:768px){
    .container{
        padding: 0 11%;
    }

.container1 {
        display: flex;
        flex-direction: column;
        max-width: 80%;
        align-items: center;

    }
    .box{
        width: 100%!important;
    }
}

/* Creating a simple box in HTML is straightforward. You can use a combination of HTML and CSS to style it. Here’s a basic example to illustrate how to create a box. Step 1: Basic HTML Structure Create an HTML file (e.g., index.html) with the following structure: html Copy code < !DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>Simple Box</title><link rel="stylesheet" href="styles.css">< !-- Link to CSS file --></head><body><div class="box"><h3 class="box-title">Box Title</h3><p class="box-content">This is the content of the box.</p></div></body></html>Step 2: Add CSS for Styling Create a CSS file (e.g., styles.css) to style your box: css Copy code body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
} */

.box {
    border: 2px solid #000;
    /* Border color */
    border-radius: 5px;
    /* Rounded corners */
    padding:15px;
    /* Space inside the box */
    margin: 10px;
    /* Space outside the box */
    background-color: #ffffff;
    /* White background */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    /* max-width: 25%; */
    width: 25%;

    margin-left: auto;
    margin-top: 50px;
 
 border: none;
    /* Limit the width */
}