.cardContainer__container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.cardContainer__sub__container{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px 0;
}
.cardContainer__Cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}


 /* Maximum Width for Small (SM) Laptops */
 @media (max-width: 900px) {
    /* Your CSS Styles for Small (SM) Laptop */
   .cardContainer__sub__container{
    width: 85%;
   }
    
  }

   /* Maximum Width for Small (SM) Laptops */
   @media (max-width: 600px) {
    /* Your CSS Styles for Small (SM) Laptop */
   .cardContainer__sub__container{
    width: 85%;
   }
    
  }