.webdev__container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   padding-top: 54px;
}

.webdev__wholecontent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.cardlist__main__container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    background-color: #f7f7f7;
}


