.message__container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 2rem 0rem;
    margin-bottom: 32px;
}

.message__heading p:first-child{
    color: #000;

font-family: Catamaran;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 21px; /* 95.455% */
}
.message__heading p:nth-child(2){
    color: #393939;

font-family: Catamaran;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 19px; /* 126.667% */
padding-top: 2rem;
padding-bottom: .5rem;
width: 70%;
}
.message__heading p:nth-child(3){
    color: #393939;

font-family: Catamaran;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 19px; /* 126.667% */
width: 70%;
}

.message__form{
    margin: 2rem 0rem;
   /*  background-color: cornflowerblue; */
    width: 100%;
}

.message__form_line1{
    display: flex;
    gap: 20px;
    justify-content: space-between;
    padding: 1rem 0rem;
}

.message__button button{
  margin-top: 1rem;
    width: 200px;
    height: 50px;
    background-color: #000;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: var(--fontprimary);
    font-size: 15px;
    font-weight: 300;
    border: none;
    transition: all 0.3s ease-in-out;
}
.message__button button:hover{
  color: #000;
  border: 1px solid #000;
  background-color: transparent;
}
/* .buttonsettext{
  font-family: "'Signika Negative', 'sans-serif'";
} */

.inputbox__container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
 
}
.inputbox__container p:first-child{
  color: #000;

font-family: var(--fontprimary);
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 100% */
}
.inputbox__container p {
  color: #BFBFBF;

font-family: var(--fontprimary);
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 100% */
}
.inputbox__container input,
.inputbox__container select{
  border: none;
  background-color:transparent;
  border-bottom: 1px solid #000;
  width: 100%;
  font-size: 18px;
  font-family: var(--fontprimary);
  font-weight: 500;
  margin: 6px 0px;
  outline: none;
  color: #666666;
  
}
.dropdown__box{
  width: 100%;
  position: relative;
}
.dropdown__Selected_Date{
  width: 100%;
  height: 25px;
  background-color:transparent;
  color: #666666;
  border-bottom: 1px solid #000;
  font-size: 18px;
  font-family: var(--fontprimary);
  font-weight: 500;
  margin-top: 6px;
  position: relative;
}
.dropdown__icons{
  position: absolute;
  right: 10px;
  top: 50%;
}
.dropdown__icons span{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.dropdown__options__box{
  display: none;
  position: absolute;
  top: 38px;
  background-color: #fff;
  width: 100%;
  padding: 6px 10px;
  border: 1px solid black;
  border-radius: 8px;
  z-index: 1;
  line-height: 2;
  
}
.dropdown__box:hover 
.dropdown__options__box {
  display: block;
}
.dropdown__icons span:nth-child(1) svg{
  transition: all 0.2s ease-in-out;
  rotate: 25deg;
}

.dropdown__icons span:nth-child(2) svg{
  transition: all 0.2s ease-in-out;
  rotate: -25deg;
}
.dropdown__options__box> h5{
  color:#000;
  color: #000;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}




@media (min-width: 1600px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }




@media (min-width: 1920px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }



@media (min-width: 2241px) {
    /* Your CSS Styles for all devices larger than 2240px */
  }




  
  /* Minimum Width for All Devices */




/* ///////for larger//////////////////////////////////////////////////////////////////////////////////////////////////////
 */




@media (max-width: 1439px) {
    /* Your CSS Styles for Large (LG) Device */
  }
  
  /* Maximum Width for Medium (MD) Laptops */
  @media (max-width: 1199px) {
    /* Your CSS Styles for Medium (MD) Laptop */
  }
  
  /* Maximum Width for Small (SM) Laptops */
  @media (max-width: 1023px) {
    /* Your CSS Styles for Small (SM) Laptop */

  }



  
  /* Maximum Width for Large (LG) Tablets */
  @media (max-width: 991px) {
    /* Your CSS Styles for Large (LG) Tablet */
    .message__heading p:nth-child(2) {
      width: 80%;
    }
    .message__heading p:nth-child(3) {
      width: 80%;
    }
    .contact__companydetails__content {
      width: 99%;
    
      /* background-color: brown; */
  }
  .inputbox__container p:first-child {
    font-size: 19px;
}
.inputbox__container input {
    font-size: 16px;
}

  }
  
  /* Maximum Width for Medium (MD) Tablets */
  @media (max-width: 767px) {
    /* Your CSS Styles for Medium (MD) Tablet */
    .message__heading p:nth-child(2) {
      width: 90%;
    }
    .message__heading p:nth-child(3) {
      width: 90%;
    }
    .inputbox__container p:first-child {
      font-size: 17px;
  }
  }
  
  /* Maximum Width for Small (SM) Mobiles */
  @media (max-width: 575px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .message__heading p:nth-child(2) {
   
      padding-bottom: 0.3rem;
      width: 91%;
  }
  .message__heading p:nth-child(3) {
   
    padding-bottom: 0.3rem;
    width: 91%;
}
  }
  
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 400px) {
    /* Your CSS Styles for Extra Small (XS) Mobile */
    .message__form_line1{
      flex-direction: column;
    }
  }