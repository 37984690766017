.cardlist__container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    justify-content: space-between;
    padding: 2rem 0;

}


.cardlistitem{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    aspect-ratio: 1;
  /*   background-color: #F5F5F5; */
    flex-direction: column;
  


}
.cardlistitem img{
   width: 30px;
   height: 30px;
   margin: .39rem;
}
.cardlistitem p{
    color: #000;

    text-align: center;
    font-family: var(--fontsecondary);
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px; /* 107.692% */
    text-align: center;
    width: 87px;
}

@media (min-width: 1600px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }




@media (min-width: 1920px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }



@media (min-width: 2241px) {
    /* Your CSS Styles for all devices larger than 2240px */
  }




  
  /* Minimum Width for All Devices */




/* ///////for larger////////////////////////////////////////////////////////////////////////////////////////////////////// */





@media (max-width: 1439px) {
    /* Your CSS Styles for Large (LG) Device */
  } 
   @media (max-width: 1200px) {
    /* Your CSS Styles for Medium (MD) Laptop */
    .cardlistitem{
      width: 125px;
    }
   
  }
  
  /* Maximum Width for Medium (MD) Laptops */
  @media (max-width: 1199px) {
    /* Your CSS Styles for Medium (MD) Laptop */
    
  }
  
  /* Maximum Width for Small (SM) Laptops */
  @media (max-width: 1023px) {
    /* Your CSS Styles for Small (SM) Laptop */
    .cardlistitem{
      width: 112px;
    }
    .cardlist__container{
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
  @media (max-width: 900px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .cardlist__container{
      width: 85%;
    }
 
  }
  /* Maximum Width for Medium (MD) Tablets */
  @media (max-width: 767px) {
    /* Your CSS Styles for Medium (MD) Tablet */
  }
  
  @media (max-width: 600px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .cardlist__container{
      width: 90%;
    }
  }
  /* Maximum Width for Small (SM) Mobiles */
  @media (max-width: 575px) {
    /* Your CSS Styles for Small (SM) Mobile */
  }
  @media(max-width:401px){
  
  }
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 340px) {
    /* Your CSS Styles for Extra Small (XS) Mobile */
  }