
.career__sub__maininnercontainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin: 10px 0 30px 0;
}
.career__heading{
    padding-top: 1rem;
    padding-bottom: .2rem;
}

.CareerPage__form__Box{
    width: 100%;
    background-color: #e8e8e8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
   
}
.CareerPage__innerF__Box{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 5rem 0;
}
.careerPage__button{
    color:#fff;
    background-color: var(--themeOrange);
    cursor: pointer;
    border: none;
    padding: 12px 20px;
}
.careerPage__button a{
    text-decoration: none;
    color: #fff;
}

@media(max-width:900px){
    .CareerPage__innerF__Box{
        width: 85%;
    }
    
}

@media(max-width:600px){
    .about__sub__maininnercontainer{
        justify-content:center;
        flex-direction: column;
        width: auto;
    }
    .CareerPage__innerF__Box{
        width: 90%;
    }
    
}