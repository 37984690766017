.newsletter__main__container{
    width: 80%;
    font-weight: 300;
}

.newsletter__container{
    display: flex;
    justify-content: center;
    align-items: center;
   margin-bottom: 1rem;
    padding: 50px 0px;
    padding-left: 20px;
    border: 1px solid #999;
}

.newsletter__text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 50%;
    

}
.newsletter__text > *{
  margin-right: 20px;
}
.newsletter__text p:first-child{
    font-family: var(--fontprimary);
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 10px;
   
}
.newsletter__img{
    width: 50%;
/* background-color: var(--pastelBlue); */
/* border: 1px solid #999; */
height: 270px;
display: flex;
justify-content: center;
align-items: center;
border-bottom-right-radius: 11px;
border-top-right-radius: 11px;
}
.newsletter__img img{
    width:400px;
    border-bottom-right-radius: 11px;
    border-top-right-radius: 11px;
/*     height: 320px; */

    
}
.newsletter__text input{
    width: 400px;
    height: 39px;
    margin: 10px 20px;
    border-radius: 5px;
    color: #000;
    width: 97%;
}
.newsletter__text p:nth-child(3){
    font-family: var(--fontprimary);
    font-weight: 300;
    font-size: 13px;
    text-align: right;
    line-height: 15px;
    color: #000;
    width: unset;
    max-width: 400px;
}

.buttonset2{
    width: 120px;
    height: 50px;
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}


@media (min-width: 1600px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }




@media (min-width: 1920px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }



@media (min-width: 2241px) {
    /* Your CSS Styles for all devices larger than 2240px */
  }




  
  /* Minimum Width for All Devices */




/* ///////for larger////////////////////////////////////////////////////////////////////////////////////////////////////// */





@media (max-width: 1439px) {
    /* Your CSS Styles for Large (LG) Device */
  }
  
  /* Maximum Width for Medium (MD) Laptops */
  @media (max-width: 1199px) {
    /* Your CSS Styles for Medium (MD) Laptop */
  }
  
  /* Maximum Width for Small (SM) Laptops */
  @media (max-width: 1023px) {
    /* Your CSS Styles for Small (SM) Laptop */

    
    
  }
  @media (max-width: 1000px) {
    /* Your CSS Styles for Small (SM) Laptop */

    .newsletter__container{
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .newsletter__text{
        align-items: flex-start;
    }
    .newsletter__text p:first-child {
        font-family: var(--fontprimary);
     /*    font-weight: 600;
        font-size: 22px; */
        margin-top: 1rem;
    }
    .newsletter__text input {
        
        margin-left: 0;
        margin-top: 0;
    }
    .newsletter__text p:nth-child(3){
        text-align: left;
    }
    .buttonset2{
        margin-top: 1rem;
    }
    .newsletter__text{
        width: 90%;
    }
    .newsletter__img{
        width: 99%;
    }
  }
  @media(max-width:900px){
    .newsletter__main__container{
        width: 85%;
    }
    .newsletter__container{
   padding: 1rem 0;
    }
  }
  /* Maximum Width for Medium (MD) Tablets */
  @media (max-width: 767px) {
    /* Your CSS Styles for Medium (MD) Tablet */
  }
  @media(max-width:600px){
    .newsletter__main__container{
        width: 90%;
    }
  }
  
  /* Maximum Width for Small (SM) Mobiles */
  @media (max-width: 575px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .newsletter__img img{
        width: 290px;
    }
    .newsletter__text p:first-child{
        margin: .6rem 0;
        
    }
  }
  
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 340px) {
    /* Your CSS Styles for Extra Small (XS) Mobile */
  }