.bottomcta__container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 3rem 0;
    width: 80%;

}
.bottomcta__container button{
    text-transform: uppercase;
}

.bottomcta__containe button:hover{
    background-color: var(--themeOrange);
}


@media (max-width: 900px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .bottomcta__container{
      width: 90%;
    }
  }

  @media (max-width: 600px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .bottomcta__container{
      width: 90%;
    }
  }