.landingpage__main__container{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.landingpage__container{
    width: 80%;
    /* height: calc(94vh); */
    /* margin-top: 85px; */
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.landingpage__text{
  width: 50%;
  /* height: 70vh; */
 /*  background-color: seagreen; */
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.landingpage__images{
    /* width: 40%; */
    /* height: 70vh; */
 /*    background-color: salmon; */
    display: flex;
    justify-content: center;
    align-items:center;
}
.landingpage__heading{
  color: #000;

font-family: var(--fontprimary);
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 49px;
text-transform: uppercase;
}
.landingpage__subhead{
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    text-wrap: pretty;
    white-space: pre-line;
}

.landingpage__subheading{
    width: 500px;
    margin-top: 20px;
    font-size: 18px;
    line-height: 26px;
    text-wrap: pretty;
    white-space: pre-line;
   
}
/* .mask1 {
    -webkit-mask-image: url(/public/boxImages.svg);
    mask-image: url(/public/boxImages.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;    
  } */
.landingpage__toptext p:last-child{
    font-size: 15px;
    margin-bottom: 70px;
  }
.landingpage__toptext img{
    width: '100%';
}

.buttonset__container{
  width: 240px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--themeOrange);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #fff;
}
.buttonset__container:hover{
  background-color: transparent;
  color:var(--themeOrange);
  border: 1px solid var(--themeOrange);
}


.buttonset__container p{
  font-size: 21px;
  font-weight: 600;
 }
 

@media(min-width:1441px){
    .landingpage__toptext p:last-child{
        font-size: 16px;
        margin-bottom: 70px;
      }
}

@media(max-width:1370px){
  .landingpage__heading{
    font-size: 32px;
    line-height: 40px;
    margin: .5rem 0;
  }
    .landingpage__text{
        justify-content: center;
    }
    .landingpage__toptext img{
       width: 400px;
    }
    .landingpage__subheading{
        width: 400px;
        margin-top: 0;
        font-size: 13px;
    }

    
    .buttonset__container{
        /* width: 240px; */
        height: 58px;
      cursor: pointer;
      }
      /* .landingpage__images img{
        width: 350px;
      } */
      .buttonset__container p{
        /* font-size: 20px; */
        font-weight: 600;
        cursor: pointer;
       }
       .landingpage__toptext p:last-child{
        font-size: 15px;
        margin-bottom: 40px;
      }
}



@media(max-width:1200px){
  .landingpage__heading{
    font-size: 27px;
    line-height: 35.1px;
    margin: .5rem 0;
  }
    .landingpage__text{
        justify-content: center;
    }
    .landingpage__toptext img{
       width: 400px;
    }
    .landingpage__subheading{
        width: 400px;
        margin-top: 0;
        font-size: 13px;
    }

    
    .buttonset__container{
        width: 200px;
        height: 50px;
      }
      /* .landingpage__images img{
        width: 350px;
      } */
      .buttonset__container p{
        font-size: 20px;
        font-weight: 600;
       }
       .landingpage__toptext p:last-child{
        font-size: 15px;
        margin-bottom: 40px;
      }
}

@media(max-width:1080px){
  .landingpage__heading{
    font-size: 22px;
    line-height: 29px;
    margin: .5rem 0;
  }
    .landingpage__text{
        justify-content: center;
    }
    .landingpage__toptext img{
       width: 400px;
    }
    .landingpage__subheading{
        width: 300px;
        margin-top: 0;
        font-size: 11px;
    }
    .buttonset__container{
        width: 160px;
        height: 39px;
      }
      .buttonset__container p{
        font-size: 15px;
        font-weight: 600;
       }
       .landingpage__toptext p:last-child{
        font-size: 13px;
        margin-bottom: 30px;
        font-weight: 300;
      }
      .landingpage__main__container{
        height: auto;
        margin-top: 100px;
      }
      .landingpage__images{
        height: min-content;
      }
      .landingpage__text{
        height: min-content;
      }
      .landingpage__container{
        padding-bottom: 1rem;
      }

}
   @media(max-width:860px){
    .landingpage__heading {
      font-size: 17px;
      line-height: 22px;
      margin: 0.3rem 0;
  }
  .landingpage__toptext p:last-child {
    font-size: 12px;
    margin-bottom: 35px;
    font-weight: 300;
    line-height: 18px;
}
.buttonset__container {
  width: 114px;
  height: 31px;
}
.buttonset__container p {
  font-size: 15px;
  font-weight: 600;
}
   }


 @media(max-width:900px){
  .landingpage__text{
    width: 42.5%;
  }
  /* .landingpage__images{
    width: 42.5%;
  } */
 }


   @media(max-width:750px){
    .landingpage__toptext p:last-child {
    width: 260px;
    }
  
   }




   @media(max-width:650px){
      .slideMain {
          width: 72%;
          /* margin-left: 68px; */
          padding: 0 7%;
        }
    .landingpage__images{
display: none;
  }
  .landingpage__toptext p:last-child {
    width: 90%;
    
  }
  .landingpage__main__container{
    margin-top: 30px;
  }
  .landingpage__container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 85%;
    height: 300px;
    margin-top: 4rem;
  }
  .landingpage__text{
    width: 80%;
   
  }
   }

   @media(max-width:600px){
     .landingpage__container{
      width: 90%;
     }
     .landingpage__text{
      width: 100%;
     }
       

   }



@media(max-width:530px){
  .landingpage__container{
    padding-top: 0rem;
  }

}