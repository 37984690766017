.textset4__container{
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem 0;
    flex-direction: column;
    margin: 2rem 0;
}

.textset4__text{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.textset4__heading{
    padding: 1rem 0;
}
.textset4__subheading{
    width: 100%;
}
.subheading ul{
  list-style: none;
}
.textset4__subheading p{
    width: 100%;
}
.textset4__cards{
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  /* align-items: center; */
  /* width: 80%; */
  /* justify-content: space-between; */
  padding-top: 2rem;
   
  
}
.textset4__inner{
    margin: 1rem;
}
.textset4__img{
 display: flex;
 justify-content: center;
 align-items: center;
 /* border: 1px solid var(--themeGray); */


}
.textset4__img img{
    width: 350px;
    padding: 2rem;
}

.textset4__card{
    /* width: 140px; */
    aspect-ratio: 1;
/*     background-color: bisque; */
    /* justify-content: center; */
    display: flex;
    align-items: center;
    flex-direction: column;
  /*   background-color: beige; */
    
  
}
.textset4__card p{
    color: #000;
    text-align: center;
    font-family: var(--fontsecondary);
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px;
    text-align: center;
    width: 87px;
    margin-top: 10px;
}
.textset4__card img{
  width: 30px;
  height: 30px;
}



@media (min-width: 1600px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }




@media (min-width: 1920px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }



@media (min-width: 2241px) {
    /* Your CSS Styles for all devices larger than 2240px */
  }




  
  /* Minimum Width for All Devices */




/* ///////for larger////////////////////////////////////////////////////////////////////////////////////////////////////// */





@media (max-width: 1439px) {
    /* Your CSS Styles for Large (LG) Device */
  } 
   @media (max-width: 1200px) {
    /* Your CSS Styles for Medium (MD) Laptop */
   
  }
  
  /* Maximum Width for Medium (MD) Laptops */
  @media (max-width: 1199px) {
    /* Your CSS Styles for Medium (MD) Laptop */
    .textset4__subheading p{
        width: 80%;
    }
    .textset4__card{
        width: 110px;
    }
    .textset4__cards{
        padding-top: 1.5rem;
    }
  }
  
  /* Maximum Width for Small (SM) Laptops */
  @media (max-width: 1023px) {
    /* Your CSS Styles for Small (SM) Laptop */
    .textset4__subheading p{
        width: 80%;
    }
    .textset4__card{
        width: 100px;
    }
    .textset4__cards{
        padding-top: 1.2rem;
    }
  }
  @media (max-width: 900px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .textset4__container{
     width: 85%;
  }
  .textset4__text{
    flex-direction: column;
    align-items: flex-start;
  }
  .textset4__card{
    width: 90px;
    align-items: flex-start;
    text-align: start;
}
.textset4__cards{
    padding-top: 1.5rem;
}
.textset4__card p{
    text-align: start;

}

  }
  /* Maximum Width for Medium (MD) Tablets */
  @media (max-width: 767px) {
    /* Your CSS Styles for Medium (MD) Tablet */
    .textset4__subheading p {
        width: 95%;
    }
    .textset4__cards{
        flex-wrap: wrap;
    }
    .textset4__cards{
      
        justify-content: flex-start;
    }
    .textset4__img img {
        width: 400px;
    }
    .textset4__container{
        margin: 1rem 0;
       padding: 1rem 0;
       margin-top: 2rem;
    }
    
  }
  
  @media (max-width: 600px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .textset4__img img {
        width: 300px;
    }
    .textset4__container{
      width: 90%;
        padding: 0;
    }
   
  }
  /* Maximum Width for Small (SM) Mobiles */
  @media (max-width: 575px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .textset4__img img {
        width: 250px;
    }
  }
  @media(max-width:401px){
  
  }
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 340px) {
    /* Your CSS Styles for Extra Small (XS) Mobile */
  }