.itteamaugmentation__container,
.itteamaugmentation__innerdiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.itteamaugmentation__alloutsourcing{
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    width: 80%;
}
.itteamaugmentation__alloutsourcing .textsetmini__container:first-child .textsetmini__img{
    border-top-left-radius: 11px;
}
.itteamaugmentation__alloutsourcing .textsetmini__container:last-child .textsetmini__img{
    border-bottom-right-radius: 11px;
}
@media(min-width:1500px){
    .itteamaugmentation__alloutsourcing{
        
        grid-template-columns: repeat(3,1fr);
    }
    .textsetmini__img {
        width: 90%;
    }
    .textsetmini__subheading p {
        width:85%;
    }
}



@media(max-width:900px){

}


@media(max-width:600px){
    
.itteamaugmentation__alloutsourcing{
    padding: 1rem 0;
  
    display: grid;
    grid-template-columns: repeat(1,1fr);
}
.textsetmini__subheading p {
    width: 90%;
}
}