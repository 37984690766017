.customiseweb__main__container{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #00000008;
}
.customiseweb__container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
    padding: 2rem 0;
}

.customiseweb__container p:first-child{
    /* color: #000; */

text-align: left;
font-family: var(--fontprimary);
/* font-size: 15px; */
font-style: normal;
/* font-weight: 500; */
line-height: 21px; /* 140% */
margin: 1rem 0;
}

.customiseweb__content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.customiseweb__gridcontent{
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-template-columns: repeat(2,1fr);
    gap: 1rem;
    width: 100%;
}

.customiseweb__gridcontent a:first-child{
  border-top-left-radius: 11px;
}
.customiseweb__gridcontent a:last-child{
  border-bottom-right-radius: 11px;
}

.griddisplay{

  margin: .5rem 0;
}

.griddisplay__heading{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.griddisplay__heading img{
    width: 30px;
    margin-right: .5rem;
    margin-bottom: .5rem;
}
.griddisplay__heading p{
 
    color: #000;

text-align: left;
font-family: var(--fontsecondary);
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 21px; /* 140% */
}
.griddisplay__text {
    color: #000;
    font-family: var(--fontprimary);
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;                  
  
  }
  .customiseweb__imgbox{
    width: 24%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(2,1fr);
   gap: 1rem;
    flex-wrap: wrap;
  

  }
 .imgboxxx{
 width: 100%;
aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  /*   background-color: var(--pastelOrange); */
  border: 1px solid var(--themeGray);

 }
 .imgboxxx_img{
  width: 100%;
  height: 100%;
 }

 
 .customiseweb__imgbox>*:nth-child(6){
  border-bottom-right-radius: 11px;
 }

.customiseweb__listitem{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid var(--themeGray);
  list-style: none;
  text-decoration: none;
  }
  .customiseweb__listitem p:first-child{
    font-family: var(--fontsecondary);
    font-size: 25px;
    font-weight: 400;
  
  }
  .customiseweb__listitem p:last-child{
      font-family: var(--fontsecondary);
      font-size: 15px;
      font-weight: 300;
      color: #000;
    }
    .customiseweb__listitem img{
      width: 30px;
      height: 30px;
      /* margin-right: .5rem; */
      margin-bottom: .5rem;
      /* filter: drop-shadow(1px 1px 0px black); */
  }
  .customiseweb__listitem p{
    color: #000;
    font-family: var(--fontsecondary);
    text-align: left;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 140% */
}

 @media (min-width: 1600px) {
  /* Your CSS Styles for Extra Large (XL) Device */
}




@media (min-width: 1920px) {
  /* Your CSS Styles for Extra Large (XL) Device */
}



@media (min-width: 2241px) {
  /* Your CSS Styles for all devices larger than 2240px */
}





/* Minimum Width for All Devices */




/* ///////for larger////////////////////////////////////////////////////////////////////////////////////////////////////// */





@media (max-width: 1439px) {
  /* Your CSS Styles for Large (LG) Device */
}

/* Maximum Width for Medium (MD) Laptops */
@media (max-width: 1199px) {
  /* Your CSS Styles for Medium (MD) Laptop */
.customiseweb__content{
  flex-direction: column-reverse;
  justify-content:center;
  align-items: center;
}

.customiseweb__imgbox {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6,1fr);
  grid-template-rows: repeat(1,0fr);
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem 0;
}

.customiseweb__gridcontent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(3,1fr);
  gap: 1rem;
  /* width: 80%; */
  padding-top: 1rem;
}
}

/* Maximum Width for Small (SM) Laptops */
@media (max-width: 1023px) {
  /* Your CSS Styles for Small (SM) Laptop */
  
}
/* Maximum Width for Medium (MD) Tablets */
@media (max-width: 900px) {
  /* Your CSS Styles for Medium (MD) Tablet */
  .customiseweb__container{
    width: 85%;
  }
.customiseweb__gridcontent{
  /* width: 85%; */
  grid-template-columns: repeat(2,1fr);
}
}


/* Maximum Width for Medium (MD) Tablets */
@media (max-width: 767px) {
  /* Your CSS Styles for Medium (MD) Tablet */
  .customiseweb__gridcontent{
    padding-top: 0;
  }
}
@media (max-width: 600px) {
  /* Your CSS Styles for Medium (MD) Tablet */
  .customiseweb__container{
    width: 90%;
  }
  .customiseweb__gridcontent{
    grid-template-columns: repeat(1,1fr);
  }
  .customiseweb__imgbox{
    grid-template-columns: repeat(3,1fr);
    width: 80%;
  }
  .customiseweb__container p:first-child{
    margin: 0rem;
  }
}

