.textsetservices__container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 2rem 0;
    width: 80%;
}
.textsetservices__heading{
 padding: .5rem 0;
}
.textsetservices__subheading P{
    width: 48%;
}

.textsetservices__cards{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem 0;
}

.textsetservices__card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 120px;
    aspect-ratio: 1;
    margin-right: 1rem;
    border:1px solid var(--themeGray);
    margin-bottom: 1rem;
}

.textsetservices__card p{
    color: #000;

    text-align: center;
    font-family: var(--fontsecondary);
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px; /* 107.692% */
    text-align: center;
    width: 87px;
}
.textsetservices__card img{
    width: 25px;
    margin: .39rem;
}


@media (min-width: 1600px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }




@media (min-width: 1920px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }



@media (min-width: 2241px) {
    /* Your CSS Styles for all devices larger than 2240px */
  }




  
  /* Minimum Width for All Devices */




/* ///////for larger////////////////////////////////////////////////////////////////////////////////////////////////////// */





@media (max-width: 1439px) {
    /* Your CSS Styles for Large (LG) Device */
  }
  
  /* Maximum Width for Medium (MD) Laptops */
  @media (max-width: 1199px) {
    /* Your CSS Styles for Medium (MD) Laptop */
  }
  
  /* Maximum Width for Small (SM) Laptops */
  @media (max-width: 1023px) {
    /* Your CSS Styles for Small (SM) Laptop */
    
  }
  @media(max-width:900px){
    .textsetservices__container{
      width: 85%;
        padding: 1rem 0;
        padding-bottom: 0;
    }
  }
  
  /* Maximum Width for Medium (MD) Tablets */
  @media (max-width: 767px) {
    /* Your CSS Styles for Medium (MD) Tablet */
    .textsetservices__subheading P {
        width: 70%;
    }
  }
  @media(max-width:600px){
    .textsetservices__container{
      width: 90%;
    }
  }
  
  /* Maximum Width for Small (SM) Mobiles */
  @media (max-width: 575px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .textsetservices__subheading P {
        width: 90%;
    }
    .textsetservices__card{
        width: 101px;
    }
  }

  
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 399px) {
    /* Your CSS Styles for Extra Small (XS) Mobile */
    .textsetservices__card{
        width: 90px;
    }
    .textsetservices__cards{
      justify-content: center;
    }
  }