.contact__container{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.conatact__companydetails{
    width: 80%;
    padding-top: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: aqua; */
}

.contact__companydetails__img{
     width: 30%;
     height: calc(wid);
   /*   background-color: var(--pastelLeaf); */
   /* border: 1px solid #999; */
     display: flex;
     justify-content: center;
     align-items: center;
     border-top-left-radius: 11px;
     aspect-ratio: 1.5;
     
}
.contact__companydetails__img img{
    width: 100%;
}

.contact__companydetails__content{
   width: 68%;
   margin-left: 2%;
 /*   background-color: brown; */
}
.contact__companydetails__content p:first-child{
    font-family: var(--fontprimary);
    font-weight: 600;
    font-size: 22px;
}
.contact__companydetails__content p:nth-child(2){
    font-family: var(--fontprimary);
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 10px;
    color: rgb(46, 46, 46);
}

.contact__companydetails__detail{
    width: 100%;
    aspect-ratio: 5;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    
}

.contactdetails__icons{
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px;
    align-items: center;
    padding-left: 0px;

}
.contactdetails__icons img{
    width: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
}
.contactdetails__icons p:nth-child(2){
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
    margin-bottom: 0;
    font-weight: 300;
    font-family: var(--fontprimary);
    color: #6A6262;
    font-size: 15px;
    line-height: 17px;
}

.contact__form{
    margin-top: 3rem;
    width: 80%;
   
}






@media (min-width: 1600px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }




@media (min-width: 1920px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }



@media (min-width: 2241px) {
    /* Your CSS Styles for all devices larger than 2240px */
  }




  
  /* Minimum Width for All Devices */




/* ///////for larger////////////////////////////////////////////////////////////////////////////////////////////////////// */





@media (max-width: 1439px) {
    /* Your CSS Styles for Large (LG) Device */
  }
  
  /* Maximum Width for Medium (MD) Laptops */
  @media (max-width: 1199px) {
    /* Your CSS Styles for Medium (MD) Laptop */
    .conatact__companydetails {
        padding-top: 2rem;
    }
    .contact__companydetails__content:first-child p{
        font-size: 18px;
    }
    .contact__companydetails__content:nth-child(2) p{
    font-size: 18px;
    }
    .contactdetails__icons p:last-child{
        font-size: 15px;
    }
    .conatact__companydetails{
        flex-direction: column;
        align-items: flex-start;
    }
    .contact__companydetails__img{
        margin: 1rem 0;
    }
    .contact__companydetails__content{
        padding-top: 1rem;
        margin-left: 0;
    }
    .contact__form{
        margin-top: 1rem;
    }
  }
  
  /* Maximum Width for Small (SM) Laptops */
  @media (max-width: 1023px) {
    /* Your CSS Styles for Small (SM) Laptop */
    .contact__companydetails__content:first-child p{
        font-size: 17px;
    }
    .contact__companydetails__content:nth-child(2) p{
    font-size: 17px;
    line-height: 20px;
    }
    .contact__companydetails__img {
        width: 50%;
    }
    
  }

  @media (max-width: 900px) {
    /* Your CSS Styles for Small (SM) Mobile */
   .contact__form{
    width: 85%;
   }
   .conatact__companydetails{
    width: 85%;
   }
  }
  
  /* Maximum Width for Medium (MD) Tablets */
  @media (max-width: 767px) {
    /* Your CSS Styles for Medium (MD) Tablet */
    .contact__companydetails__content:first-child p{
        font-size: 15px;
    }
    .contact__companydetails__content:nth-child(2) p{
    font-size: 15px;
    }
    .contact__companydetails__img {
        width: 59%;
    }
  }

    /* Maximum Width for Small (SM) Mobiles */
    @media (max-width: 600px) {
        /* Your CSS Styles for Small (SM) Mobile */
       .contact__form{
        width: 90%;
       }
       .conatact__companydetails{
        width: 90%;
       }
      }
      
  
  /* Maximum Width for Small (SM) Mobiles */
  @media (max-width: 575px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .contact__companydetails__img {
        width: 80%;
    }
    
  }
  
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 340px) {
    /* Your CSS Styles for Extra Small (XS) Mobile */
  }