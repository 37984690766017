.popular__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    width: 80%;
}

.popular__heading{
    margin-right: auto;
    /* padding: 1rem 0; */
}
.popular__list{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 1rem;
  width: 100%;
  
}




.popular__numbering{
  display: flex;
  width: 200px;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  padding: 20px;
}

.popular__numbering p{
  font-family: var(--fontsecondary);
  font-weight: 800;
  color: #000;
  font-size: 18px;
}


.popular__imgcontain{
   width: 100%;
   height: 200px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.popular__imgcontain img{
  width: 150px;
}

.popular__card{
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: flex-start;
 /* border: 1px solid #999; */
 border-radius: 11px;
 width: 100%;
 margin: 20px 0px;
 padding-bottom: 8px;
 box-shadow: -3px -3px 8px #dedede;

}
.popular__card p:nth-child(2){
font-family: var(--fontsecondary);
font-weight: 500;
font-size: 20px;
padding: 5px 11px;
}
.popular__card p:last-child{
 font-family: var(--fontprimary);
 font-size: 15px;
 color: #505050;
 padding: 5px 11px;
 padding-top: 0;
 line-height: 21px;
}




@media (min-width: 1600px) {
  /* Your CSS Styles for Extra Large (XL) Device */
}




@media (min-width: 1920px) {
  /* Your CSS Styles for Extra Large (XL) Device */
}



@media (min-width: 2241px) {
  /* Your CSS Styles for all devices larger than 2240px */
}





/* Minimum Width for All Devices */




/* ///////for larger////////////////////////////////////////////////////////////////////////////////////////////////////// */





@media (max-width: 1439px) {
  /* Your CSS Styles for Large (LG) Device */
}

/* Maximum Width for Medium (MD) Laptops */
@media (max-width: 1199px) {
  /* Your CSS Styles for Medium (MD) Laptop */
}

/* Maximum Width for Small (SM) Laptops */
@media (max-width: 1023px) {
  /* Your CSS Styles for Small (SM) Laptop */
  
}
@media (max-width: 900px) {
  /* Your CSS Styles for Medium (MD) Tablet */
  .popular__list{
    grid-template-columns: repeat(2,1fr);
  }
  .popular__container{
    width: 85%;
  }
  .popular__imgcontain{
    width: 100%;
    height: 130px;
  }
  .popular__imgcontain img{
    width: 100px;
  }
}
/* Maximum Width for Medium (MD) Tablets */
@media (max-width: 767px) {
  /* Your CSS Styles for Medium (MD) Tablet */
  .popular__card p:nth-child(2){
   font-size: 15px;
  }
  .popular__card p:last-child{
    display: none;
  }
  .popular__card{
    margin: 0;
  }
}



/* Maximum Width for Small (SM) Mobiles */
@media (max-width: 575px) {
  .popular__container{
    width: 90%;
  }
  /* Your CSS Styles for Small (SM) Mobile */
  /* .popular__list{
    grid-template-columns: repeat(1,1fr);
  } */
  .popular__card p:nth-child(2) {
    font-size: 13px;
}

}

/* Maximum Width for Extra Small (XS) Mobiles */
@media (max-width: 300px) {
  .popular__list{
    grid-template-columns: repeat(1,1fr);
  }
}



