.textset7__container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem 0;
    width: 80%;
}

.textset7__title{
    display: flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items: center;
}

.textset7__heading{
    margin: 1rem 0;
}
.textset7__subheading p{
    width: 48%;
    white-space: 'pre-line';
 
}

.textset7__cards{
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: 100%;


}
.textset7__card{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 1rem;
}
.textset7__card__heading{
    margin-bottom: 1rem;
}


.textset7__card img{

    width: 93%;
    padding: 2rem;
    border: 1px solid var(--themeGray);
    margin-right: 1rem;

}
.textset7__img img{
    width: 200px;
}

@media (min-width: 1600px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }




@media (min-width: 1920px) {
    /* Your CSS Styles for Extra Large (XL) Device */
  }



@media (min-width: 2241px) {
    /* Your CSS Styles for all devices larger than 2240px */
  }




  
  /* Minimum Width for All Devices */




/* ///////for larger////////////////////////////////////////////////////////////////////////////////////////////////////// */





@media (max-width: 1439px) {
    /* Your CSS Styles for Large (LG) Device */
  }
  
  /* Maximum Width for Medium (MD) Laptops */
  @media (max-width: 1199px) {
    /* Your CSS Styles for Medium (MD) Laptop */
    .textset7__subheading p {
        width: 60%;
      
    }
  }
  @media(max-width:900px){
    .textset7__container{
      width: 85%;
        margin: 1rem 0;
    }
    .textset7__cards{
        margin-bottom: 1rem;
    }
  }
  
  /* Maximum Width for Small (SM) Laptops */
  @media (max-width: 1023px) {
    /* Your CSS Styles for Small (SM) Laptop */
    .textset7__subheading p {
        width: 70%;
      
    }
  }
  
  /* Maximum Width for Medium (MD) Tablets */
  @media (max-width: 767px) {
    /* Your CSS Styles for Medium (MD) Tablet */
    .textset7__subheading p {
        width: 90%;
      
    }
  }
  @media(max-width:600px){
    .textset7__container{
      width: 90%;
    }
  }
  
  /* Maximum Width for Small (SM) Mobiles */
  @media (max-width: 575px) {
    /* Your CSS Styles for Small (SM) Mobile */
    .textset7__subheading p {
        width: 95%;
      
    }
  }
  
  /* Maximum Width for Extra Small (XS) Mobiles */
  @media (max-width: 340px) {
    /* Your CSS Styles for Extra Small (XS) Mobile */
  }