.card__container{
    width: 30%;
    min-width: 300px;
    border-radius: 0 20px 0 20px;
    box-shadow: 0 0 17px rgba(0,0,0,.1);
    padding: 20px;
    margin-top: 10px;
}
.card__deatils_innerBox{
    display: flex;
    align-items: center;
    gap: 5px;
}
.card__heading{
    margin-bottom: 5px;
}

.card__Subtitle,
.card__deatils_innerBox svg{
    font-size: 13px;
    font-weight: 600;
    margin-top: 8px;
}
.card__button__container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.card__button{
    color:#fff;
    background-color: var(--themeOrange);
    cursor: pointer;
    border: none;
    padding: 10px 20px;
    transition: all 0.2s ease-in-out;
}
.card__button:hover{
    color:var(--themeOrange);
    background-color: transparent;
   border: 1px solid var(--themeOrange);
   padding: 9px 20px;
}
.form__background{
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: #0000008c;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    overflow: scroll;
    justify-content: center;
}
.form__closeButton{
    position: absolute;
    top: 15px;
    right: 20px;
}
.form__container{
    width: 80%;
    height: 80%;
    overflow: scroll;
    max-height: 65%;
    position: absolute;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 15px;
}
.form__inner__Box{
    display: flex;
    align-items: center;
    gap: 8px;
   
}
.form__input__Box{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 15px 8px;
}
.form__input__Box input{
    outline: none;
    border: none;
    border-bottom: 1px solid black;
    padding: 8px 5px;
}
.form__submit__but{
    padding: 10px 15px;
    background: var(--themeOrange);
    color: #fff;
    font-weight: 600;
    border: none;
}
.form__submit__but:hover{
    background-color: transparent;
    color: var(--themeOrange);
    border: 1px solid var(--themeOrange);
}
::-webkit-scrollbar{
    width: 0;
}

@media(max-width:600px){
    .form__container{
        width: 90%;
    }
    .form__inner__Box{
       flex-direction: column;   
    }
    .form__input__Box{
      width: 100%;
    }
    
}

@media(max-width:400px){
    .card__container{
        min-width: 250px;
    }
    
}